<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <!--
      <div class="card p-fluid">
        <h5>{{ $t('menu.warehouse.create')}}</h5>
        <div class="field grid">
					<label for="packageCode" class="col-12 mb-2 md:col-2 md:mb-0">{{$t('package.packageCode')}}</label>
					<div class="col-12 lg:col-4 md:col-4">
						<InputText id="packageCode" type="text" v-model="packageCode" />
					</div>
            <div class="col-1 lg:col-1 md:col-1">
              <Button :label="$t('button.save')" @click="onSave($event)"></Button>
            </div>
            <div class="col-1 lg:col-1 md:col-1">
              <Button :label="$t('button.reset')" @click="onReset($event)"></Button>
            </div>
            <div class="col-1 lg:col-1 md:col-1">
              <Button :label="$t('button.back')" @click="onBack()"></Button>
            </div>
				</div>

      </div>
      -->
      <CrudTable ref="shipment" :entities="entities"
        :meta="metaTable"
        @tableAction="onTableAction"
        @itemAction="onItemAction"
        @cell-edit-complete="onCellEditComplete"
        @paging="onPaging"
        @filter="onFilter"       
        @onSort="onSort"/>
    </div>
  </div>
</template>
<script>
import CrudTable from '@/pages/common/CrudTable';
import ShipmentService from '@/service/ShipmentService';
import AuthService from '@/service/AuthService';
import adjustment from '@/utils/adjustment';
import { converter } from '@/utils/common';
import formatter from '@/utils/formatter';
import {serializeOrders, serializeFilters } from '@/utils/params';
import Consts from '@/utils/consts';
//import filter from '@/mixins/filter'

export default {
  components: {CrudTable},
  //mixins: [filter],
  data() {
    return {
      entities: [],
      packageCode: null,
      currentUser: null,
      filterOptions: {
        page: 0,
				size: Consts.DEFAULT_PAGE_ROWS
      },
      metaTable : {
        entityName: "shipment",
        expander: false,
        searchable: true,
        rowEditor: true,
        editMode: "cell",
        sortMode: "multiple",
        multiSortMeta: [{field:"departure", order: -1}],
        actionsHeaderStyle: "min-width:12rem; text-align: center;",
        paginator:{
          enable: true,
          rows: Consts.DEFAULT_PAGE_ROWS,
          rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
          currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} shipments",
        },
        filter: {
          filterDisplay: "menu",
          globalFilterFields: ['shipmentCode','awbCode']
        },
        columns: [
          {
            name: "departure",
            headerStyle: "width:8%; min-width:8rem;",
            filter: true,
            dataType:"date",
            formatter: formatter.formatDate
          },
          {
            name: "shipmentCode",
            label: "shipment.code",
            headerStyle: "width:8%; min-width:8rem;",
            filter: true,
            link: this.openLink
          },
          {
            name: "awbCode",
            headerStyle: "width:10%; min-width:10rem;",
            filter: true,
            editor: {
              enable: true,
              style:"width: 120px"
            },
          },
          {
            name: "agentCode",
            label:"shipment.agent",
            headerStyle: "width:5%; min-width:5rem;",
            filter: true,
            condition:"user.orgType=='owner'"
          },
          {
            name: "weightLbs",
            label:"shipment.weightAwb",
            headerStyle: "width:10%; min-width:12rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.formatLbsWeight
          },
          {
            name: "weightSystemLbs",
            label:"shipment.weightSystem",
            headerStyle: "width:10%; min-width:13rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.formatLbsWeight
          },
          {
            name: "packages",
            label:"shipment.pieces",
            headerStyle: "width:10%; min-width:6rem;",
            filter: true,
            dataType:"numeric",
            formatter: converter.length
          },
          {
            name: "status",
            headerStyle: "width:10%; min-width:10rem;",
            filter: true,
          },
          {
            name: "note",
            headerStyle: "width:20%; min-width:18rem;",
            filter: true,
          }
        ],
        tableActions: [
          {
            name: "filterclear",
            icon: "pi pi-filter-slash",
            style: "p-button-outlined mr-2"
          },
          {
            name: "create",
            icon: "pi pi-pencil",
            style: "p-button mr-2"
          },
        ],
        itemActions: [
          {
            name: "edit",
            icon: "pi pi-pencil",
            style: "p-button-rounded mr-2",
            condition: "status != 'stored'"
          },
          {
            name: "preview",
            icon: "pi pi-print",
            style: "p-button-rounded mr-2"
          },
          {
            name: "confirm",
            icon: "pi pi-send",
            style: "p-button-rounded mr-2",
            condition:"status === 'inited'"
          }
        ]
      }
    }
  },
  mounted() {
    //this.initColumnFilters(this.$refs.dt, this.filters);
    this.currentUser = AuthService.currentUser;
    this.service = ShipmentService;
    this.filterOptions.sorts = serializeOrders({multiSortMeta: this.metaTable.multiSortMeta});
    this._loadPackages();
  },
  methods: {
    onPaging(pageEvent) {
      this.filterOptions.page = pageEvent.page;
      this.filterOptions.size =pageEvent.rows;
			this._loadPackages();
		},
    onFilter(filterEvent) {
      this.filterOptions.filter = serializeFilters(filterEvent);
      this._loadPackages();
    },
    onSort(sortEvent) {
      this.filterOptions.sorts = serializeOrders(sortEvent);
      this._loadPackages();
    },
    _loadPackages() {
      this.service.getAll(this.filterOptions).then(data => {
				if(Array.isArray(data.content)) {
          data.content.forEach(item => adjustment.adjustShipment(item));
          this.entities = data.content;
          this.metaTable.paginator.totalRecords = data.totalElements;
        } else {
          this.entities = [];
          this.metaTable.paginator.totalRecords = 0;
        }
			});
    },
    onTableAction(sAction) {
      if(sAction == 'create') {
        this.$router.push({ name: 'agent_shipment_create', params: {} });
      }
    },
    onItemAction(sAction, entity) {
      if(sAction == 'edit') {
        this.$router.push({ name: 'agent_shipment_edit', params: {id: entity.id} });
      } else if (sAction == 'preview') {
        //this.$router.push({ name: 'print_preview', params: {resource: "shipment", id: entity.id} });
        const routeData = this.$router.resolve({ name: 'print_preview', params: {resource: "shipment", id: entity.id} });
        window.open(routeData.href);         
      } else if (sAction == 'confirm') {
        this.onSendShipment(entity);
      } else if (sAction == 'delete') {
        //this.onDeleteShipment(entity);
      }
    },
    onSendShipment(entity) {
      let error = "";
      if (!entity.departure) {
        error = this.$t('shipment.missing_departure_date');
      } else if (!entity.awbCode) {
        error = this.$t('shipment.missing_awb_code');
      } 
      if (error) {
        this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000});
      } else {
        this.$confirm.require({
            message: this.$t("shipment.confirm_start"),
            header: this.$t("common.confirmation"),
            icon: "pi pi-exclamation-triangle",
            accept: () => {
              this._sendShipment(entity);
            },
            reject: () => {
              //callback to execute when user rejects the action
            },
          });        
      }      
    },
    _sendShipment(entity) {
      this.service.startShipment(entity.id).then(()=>{
          this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('shipment.confirmed'), life: 3000});
        }).catch((e) => {
          console.log(e);
        });
    },
    // onDeleteShipment(entity) {
    //   this.$confirm.require({
    //     message: this.$t("shipment.confirm_delete"),
    //     header: this.$t("common.confirmation"),
    //     icon: "pi pi-exclamation-triangle",
    //     accept: () => {
    //       this._deleteShipment(entity);
    //     },
    //     reject: () => {
    //       //callback to execute when user rejects the action
    //     },
    //   });        
    // },
    // _deleteShipment(entity) {
    //   this.service.delete(entity.id).then(()=>{
    //       this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t('shipment.deleted_successfully'), life: 3000});
    //     });
    // },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      switch (field) {
          case 'weightLbs':
          case 'weightKg':
              if (this.isPositiveInteger(newValue)) {
                  data[field] = newValue;
                  this.service.updateField(data.id, field, newValue);
              } else {
                  event.preventDefault();
              }
          break;

          default:
              if (newValue != null && newValue.trim().length > 0) {
                  data[field] = newValue;
                  this.service.updateField(data.id, field, newValue);
              }
          break;
      }
    },
    isPositiveInteger(val) {
        let str = String(val);
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    },
    openLink(data) {
      this.$router.push({ name: 'agent_shipment_detail', params: {id: data.id} })
    }
  }
}
</script>
